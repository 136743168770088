import revive_payload_client_xWXYKXTlN1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._523hxvdsfwgrjuzah3x4h4jkiq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sZ98t2xkKH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._523hxvdsfwgrjuzah3x4h4jkiq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KVvFp5VJOt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._523hxvdsfwgrjuzah3x4h4jkiq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_mmWeg4BpSt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.11_sass@1.7_mo23kvsfgkwrg3hgnrsbaynj2y/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Sus1W57RoC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._523hxvdsfwgrjuzah3x4h4jkiq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_c4zIMePK1T from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._523hxvdsfwgrjuzah3x4h4jkiq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_ypgeUhdnLf from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._523hxvdsfwgrjuzah3x4h4jkiq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/web-locals/.nuxt/components.plugin.mjs";
import prefetch_client_X6jb2Tl56C from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._523hxvdsfwgrjuzah3x4h4jkiq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_yIaui1jKcO from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.2.1_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.11_sass@1.77.8_ter_7s4xzgvcxq3qzgyqakkimypqzm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import imgwin_client_SiHf1aOUrN from "/opt/buildhome/repo/web/plugins/imgwin.client.ts";
import sentry_client_A9HLV6Ua2r from "/opt/buildhome/repo/web/plugins/sentry.client.ts";
import vue_final_modal_qbCQSD8hof from "/opt/buildhome/repo/web/plugins/vue-final-modal.ts";
import vue_gtm_client_HlOuhZpEoF from "/opt/buildhome/repo/web/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_xWXYKXTlN1,
  unhead_sZ98t2xkKH,
  router_KVvFp5VJOt,
  _0_siteConfig_mmWeg4BpSt,
  payload_client_Sus1W57RoC,
  navigation_repaint_client_c4zIMePK1T,
  chunk_reload_client_ypgeUhdnLf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_X6jb2Tl56C,
  plugin_yIaui1jKcO,
  imgwin_client_SiHf1aOUrN,
  sentry_client_A9HLV6Ua2r,
  vue_final_modal_qbCQSD8hof,
  vue_gtm_client_HlOuhZpEoF
]