import { default as _404MbAFY1GVUxMeta } from "/opt/buildhome/repo/web/pages/404.vue?macro=true";
import { default as _91blog_num_932cxK66N0UGMeta } from "/opt/buildhome/repo/web/pages/BLOG/[blog_id]/blog.php/[blog_num].vue?macro=true";
import { default as indexLOvXtFxcwAMeta } from "/opt/buildhome/repo/web/pages/BLOG/[blog_id]/index.vue?macro=true";
import { default as _91tag_93LXGSYiSz55Meta } from "/opt/buildhome/repo/web/pages/BLOG/[blog_id]/tag.php/[tag].vue?macro=true";
import { default as _91_46_46_46slug_931xT2mLGvYAMeta } from "/opt/buildhome/repo/web/pages/cache/[...slug].vue?macro=true";
import { default as _91dir_name_93F9PR3GidGOMeta } from "/opt/buildhome/repo/web/pages/contents.php/[dir_name].vue?macro=true";
import { default as contest0muKWxwTuzMeta } from "/opt/buildhome/repo/web/pages/contents.php/contest.vue?macro=true";
import { default as contest_91contest_year_93lPBdqphp3zMeta } from "/opt/buildhome/repo/web/pages/contents.php/contest[contest_year].vue?macro=true";
import { default as indexufRSzRPnzHMeta } from "/opt/buildhome/repo/web/pages/contents.php/index.vue?macro=true";
import { default as p__91shop_name_938sI8q7pKzLMeta } from "/opt/buildhome/repo/web/pages/contents.php/p_[shop_name].vue?macro=true";
import { default as e_listYj7aHHUBfGMeta } from "/opt/buildhome/repo/web/pages/e_list.vue?macro=true";
import { default as _91_46_46_46contest_year_939oY0MX0AE3Meta } from "/opt/buildhome/repo/web/pages/html/contest/[...contest_year].vue?macro=true";
import { default as _91year2_93_45_91num_93NNnRWkcLPmMeta } from "/opt/buildhome/repo/web/pages/html/contest/[year]/[year2]-[num].vue?macro=true";
import { default as index0Ko0NHG3m8Meta } from "/opt/buildhome/repo/web/pages/index.vue?macro=true";
import { default as confirmS4Yz0FnYEUMeta } from "/opt/buildhome/repo/web/pages/inquiry/confirm.vue?macro=true";
import { default as exitUXoBD8fsDmMeta } from "/opt/buildhome/repo/web/pages/inquiry/exit.vue?macro=true";
import { default as formd2c7vQT2OTMeta } from "/opt/buildhome/repo/web/pages/inquiry/form.vue?macro=true";
import { default as l_91kouji_cd_93aSZw3bUqvLMeta } from "/opt/buildhome/repo/web/pages/letter_e.php/l[kouji_cd].vue?macro=true";
import { default as newsSDk1raLijwMeta } from "/opt/buildhome/repo/web/pages/news.vue?macro=true";
import { default as _91plan_cd_93JCzjh65Y69Meta } from "/opt/buildhome/repo/web/pages/plan/detail/[plan_cd].vue?macro=true";
import { default as indexrZmhuIPmBUMeta } from "/opt/buildhome/repo/web/pages/plan/index.vue?macro=true";
import { default as careerRqRLCNvihGMeta } from "/opt/buildhome/repo/web/pages/recruit/career.vue?macro=true";
import { default as confirmsDF7HyZxc8Meta } from "/opt/buildhome/repo/web/pages/recruit/confirm.vue?macro=true";
import { default as contractorQfJg28RbopMeta } from "/opt/buildhome/repo/web/pages/recruit/contractor.vue?macro=true";
import { default as exitdeZD84n28VMeta } from "/opt/buildhome/repo/web/pages/recruit/exit.vue?macro=true";
import { default as newgradHZbZoPOoEAMeta } from "/opt/buildhome/repo/web/pages/recruit/newgrad.vue?macro=true";
import { default as _91cd_num_93ZQU3NXzz2WMeta } from "/opt/buildhome/repo/web/pages/sekou/detail/[cd_num].vue?macro=true";
import { default as exterior_91attr_num_93FRPh2uOkuWMeta } from "/opt/buildhome/repo/web/pages/sekou/exterior[attr_num].vue?macro=true";
import { default as garden_91attr_num_93ar9ZmuzrgEMeta } from "/opt/buildhome/repo/web/pages/sekou/garden[attr_num].vue?macro=true";
import { default as hyogo_91_46_46_46city_93H4OQ6qOtsNMeta } from "/opt/buildhome/repo/web/pages/sekou/hyogo[...city].vue?macro=true";
import { default as indexceISi08oYgMeta } from "/opt/buildhome/repo/web/pages/sekou/index.vue?macro=true";
import { default as itemI3h5gSKaTJMeta } from "/opt/buildhome/repo/web/pages/sekou/item.vue?macro=true";
import { default as keyword_46phpqsO3b2S4IPMeta } from "/opt/buildhome/repo/web/pages/sekou/keyword.php.vue?macro=true";
import { default as kyoto_91_46_46_46city_935ULw3ZjMG4Meta } from "/opt/buildhome/repo/web/pages/sekou/kyoto[...city].vue?macro=true";
import { default as nara_91_46_46_46city_93NzQIEzmbfRMeta } from "/opt/buildhome/repo/web/pages/sekou/nara[...city].vue?macro=true";
import { default as osaka_91_46_46_46city_93aJjVyfZLWLMeta } from "/opt/buildhome/repo/web/pages/sekou/osaka[...city].vue?macro=true";
import { default as reform_91attr_num_93a3pLWyQkbWMeta } from "/opt/buildhome/repo/web/pages/sekou/reform[attr_num].vue?macro=true";
import { default as search_46php3O8alLjOWCMeta } from "/opt/buildhome/repo/web/pages/sekou/search.php.vue?macro=true";
import { default as shiga_91_46_46_46city_93Cdpx0bp3DbMeta } from "/opt/buildhome/repo/web/pages/sekou/shiga[...city].vue?macro=true";
import { default as style_91attr_num_93lWnNc6SxfzMeta } from "/opt/buildhome/repo/web/pages/sekou/style[attr_num].vue?macro=true";
import { default as tatemono_91attr_num_93lOE6jStKdrMeta } from "/opt/buildhome/repo/web/pages/sekou/tatemono[attr_num].vue?macro=true";
import { default as wakayama_91_46_46_46city_930eBKpi1YUtMeta } from "/opt/buildhome/repo/web/pages/sekou/wakayama[...city].vue?macro=true";
import { default as zone_91attr_num_93Cwr4x8mxdQMeta } from "/opt/buildhome/repo/web/pages/sekou/zone[attr_num].vue?macro=true";
import { default as _91key_93onYDwmvlIZMeta } from "/opt/buildhome/repo/web/pages/thxtel.php/[key].vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/opt/buildhome/repo/web/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "BLOG-blog_id-blog.php-blog_num",
    path: "/BLOG/:blog_id()/blog.php/:blog_num()",
    component: () => import("/opt/buildhome/repo/web/pages/BLOG/[blog_id]/blog.php/[blog_num].vue").then(m => m.default || m)
  },
  {
    name: "BLOG-blog_id",
    path: "/BLOG/:blog_id()",
    component: () => import("/opt/buildhome/repo/web/pages/BLOG/[blog_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "BLOG-blog_id-tag.php-tag",
    path: "/BLOG/:blog_id()/tag.php/:tag()",
    component: () => import("/opt/buildhome/repo/web/pages/BLOG/[blog_id]/tag.php/[tag].vue").then(m => m.default || m)
  },
  {
    name: "cache-slug",
    path: "/cache/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/web/pages/cache/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "contents.php-dir_name",
    path: "/contents.php/:dir_name()",
    component: () => import("/opt/buildhome/repo/web/pages/contents.php/[dir_name].vue").then(m => m.default || m)
  },
  {
    name: "contents.php-contest",
    path: "/contents.php/contest",
    component: () => import("/opt/buildhome/repo/web/pages/contents.php/contest.vue").then(m => m.default || m)
  },
  {
    name: "contents.php-contestcontest_year",
    path: "/contents.php/contest:contest_year()",
    component: () => import("/opt/buildhome/repo/web/pages/contents.php/contest[contest_year].vue").then(m => m.default || m)
  },
  {
    name: "contents.php",
    path: "/contents.php",
    component: () => import("/opt/buildhome/repo/web/pages/contents.php/index.vue").then(m => m.default || m)
  },
  {
    name: "contents.php-p_shop_name",
    path: "/contents.php/p_:shop_name()",
    component: () => import("/opt/buildhome/repo/web/pages/contents.php/p_[shop_name].vue").then(m => m.default || m)
  },
  {
    name: "e_list",
    path: "/e_list",
    component: () => import("/opt/buildhome/repo/web/pages/e_list.vue").then(m => m.default || m)
  },
  {
    name: "html-contest-contest_year",
    path: "/html/contest/:contest_year(.*)*",
    component: () => import("/opt/buildhome/repo/web/pages/html/contest/[...contest_year].vue").then(m => m.default || m)
  },
  {
    name: "html-contest-year-year2-num",
    path: "/html/contest/:year()/:year2()-:num()",
    component: () => import("/opt/buildhome/repo/web/pages/html/contest/[year]/[year2]-[num].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index0Ko0NHG3m8Meta || {},
    component: () => import("/opt/buildhome/repo/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-confirm",
    path: "/inquiry/confirm",
    meta: confirmS4Yz0FnYEUMeta || {},
    component: () => import("/opt/buildhome/repo/web/pages/inquiry/confirm.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-exit",
    path: "/inquiry/exit",
    meta: exitUXoBD8fsDmMeta || {},
    component: () => import("/opt/buildhome/repo/web/pages/inquiry/exit.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-form",
    path: "/inquiry/form",
    meta: formd2c7vQT2OTMeta || {},
    component: () => import("/opt/buildhome/repo/web/pages/inquiry/form.vue").then(m => m.default || m)
  },
  {
    name: "letter_e.php-lkouji_cd",
    path: "/letter_e.php/l:kouji_cd()",
    component: () => import("/opt/buildhome/repo/web/pages/letter_e.php/l[kouji_cd].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/opt/buildhome/repo/web/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "plan-detail-plan_cd",
    path: "/plan/detail/:plan_cd()",
    component: () => import("/opt/buildhome/repo/web/pages/plan/detail/[plan_cd].vue").then(m => m.default || m)
  },
  {
    name: "plan",
    path: "/plan",
    component: () => import("/opt/buildhome/repo/web/pages/plan/index.vue").then(m => m.default || m)
  },
  {
    name: "recruit-career",
    path: "/recruit/career",
    meta: careerRqRLCNvihGMeta || {},
    component: () => import("/opt/buildhome/repo/web/pages/recruit/career.vue").then(m => m.default || m)
  },
  {
    name: "recruit-confirm",
    path: "/recruit/confirm",
    meta: confirmsDF7HyZxc8Meta || {},
    component: () => import("/opt/buildhome/repo/web/pages/recruit/confirm.vue").then(m => m.default || m)
  },
  {
    name: "recruit-contractor",
    path: "/recruit/contractor",
    meta: contractorQfJg28RbopMeta || {},
    component: () => import("/opt/buildhome/repo/web/pages/recruit/contractor.vue").then(m => m.default || m)
  },
  {
    name: "recruit-exit",
    path: "/recruit/exit",
    meta: exitdeZD84n28VMeta || {},
    component: () => import("/opt/buildhome/repo/web/pages/recruit/exit.vue").then(m => m.default || m)
  },
  {
    name: "recruit-newgrad",
    path: "/recruit/newgrad",
    meta: newgradHZbZoPOoEAMeta || {},
    component: () => import("/opt/buildhome/repo/web/pages/recruit/newgrad.vue").then(m => m.default || m)
  },
  {
    name: "sekou-detail-cd_num",
    path: "/sekou/detail/:cd_num()",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/detail/[cd_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-exteriorattr_num",
    path: "/sekou/exterior:attr_num()",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/exterior[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-gardenattr_num",
    path: "/sekou/garden:attr_num()",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/garden[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-hyogocity",
    path: "/sekou/hyogo:city(.*)*",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/hyogo[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou",
    path: "/sekou",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/index.vue").then(m => m.default || m)
  },
  {
    name: "sekou-item",
    path: "/sekou/item",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/item.vue").then(m => m.default || m)
  },
  {
    name: "sekou-keyword.php",
    path: "/sekou/keyword.php",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/keyword.php.vue").then(m => m.default || m)
  },
  {
    name: "sekou-kyotocity",
    path: "/sekou/kyoto:city(.*)*",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/kyoto[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-naracity",
    path: "/sekou/nara:city(.*)*",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/nara[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-osakacity",
    path: "/sekou/osaka:city(.*)*",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/osaka[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-reformattr_num",
    path: "/sekou/reform:attr_num()",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/reform[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-search.php",
    path: "/sekou/search.php",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/search.php.vue").then(m => m.default || m)
  },
  {
    name: "sekou-shigacity",
    path: "/sekou/shiga:city(.*)*",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/shiga[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-styleattr_num",
    path: "/sekou/style:attr_num()",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/style[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-tatemonoattr_num",
    path: "/sekou/tatemono:attr_num()",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/tatemono[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-wakayamacity",
    path: "/sekou/wakayama:city(.*)*",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/wakayama[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-zoneattr_num",
    path: "/sekou/zone:attr_num()",
    component: () => import("/opt/buildhome/repo/web/pages/sekou/zone[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "thxtel.php-key",
    path: "/thxtel.php/:key()",
    component: () => import("/opt/buildhome/repo/web/pages/thxtel.php/[key].vue").then(m => m.default || m)
  }
]