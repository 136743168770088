import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@9.7.0_ioredis@5.4.1_magicast@0._523hxvdsfwgrjuzah3x4h4jkiq/node_modules/nuxt/dist/pages/runtime/validate.js";
import history_45global from "/opt/buildhome/repo/web/middleware/history.global.ts";
import pageview_45global from "/opt/buildhome/repo/web/middleware/pageview.global.ts";
import redirect_45global from "/opt/buildhome/repo/web/middleware/redirect.global.ts";
export const globalMiddleware = [
  validate,
  history_45global,
  pageview_45global,
  redirect_45global
]
export const namedMiddleware = {}